<template>
  <v-autocomplete
      v-model="selected"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      item-title="projectName"
      item-value="fullUrl"
      placeholder="Search games..."
      hide-details
      density="compact"
      class="header-search"
      @update:search="onSearch"
      @update:model-value="onSelect"
      no-data-text=""
      :custom-filter="() => true"
  >
    <!-- custom filter is needed because vuetify tries to narrow down the results client side.
     Setting it to true means that it just uses the raw data from the server, since that search is fuzzy.-->
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props">
        <template v-slot:prepend>
          <v-img
              :src="filestackUrl(item.raw.screenshotUrl)"
              width="50"
              height="50"
              cover
              class="rounded mr-3"
          />
        </template>
<!--        <v-list-item-title>{{ item.raw.projectName }}</v-list-item-title>-->
        <v-list-item-subtitle>
          by {{ item.raw.username }}
          <span v-if="item.raw.studioName">
            ({{ item.raw.studioName }})
          </span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>



<script setup>
import callFirebaseFunction from '../helpers/callFirebaseFunction'
import { debounce } from '../helpers/helpers'

const selected = ref(null)
const loading = ref(false)
const items = ref([])
const search = ref('')

const onSelect = (value) => {
  if (!value) return
  navigateTo(`${value}`)
  selected.value = null
  search.value = ''
}

const performSearch = async (val) => {
  if (!val) {
    items.value = []
    return
  }

  loading.value = true
  try {
    const result = await callFirebaseFunction('search', {query: val})
    items.value = result.data || [] // Ensure we always have an array
  } catch (error) {
    console.error('Search error:', error)
    items.value = []
  } finally {
    loading.value = false
  }
}

const filestackUrl = (url) => {
  if (!url) return null
  return `https://process.filestackapi.com/AnP4G3pixRCyMaUo5jr8bz/cache=expiry:max/resize=height:75/${url}`
}

const onSearch = debounce(performSearch, 300)
</script>


<style scoped>
.header-search {
  width: 300px;
}
</style>
