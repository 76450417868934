<template>
  <v-app-bar class="pl-4 pr-4 header-bar" :style="{ backgroundColor: `rgba(var(--v-theme-surface), ${opacity})` }">
    <div class="d-flex align-center header-content">
      <NuxtLink to="/" class="text-decoration-none d-flex align-center">
        <v-img
            src="/images/simmernewyellow.png"
            :width="40"
            :height="40"
            class="mr-2"
        />
        <span class="text-h6 hidden-xs">simmer.io</span>
      </NuxtLink>
    </div>

    <v-spacer></v-spacer>

    <HeaderSearch class="mx-4" />
    <v-spacer></v-spacer>

    <div class="d-flex align-center">
      <NuxtLink v-if="membershipStore.isPaid" to="/articles/simmerconnect-subscription">
        <v-btn
            icon="mdi-star"
            size="small"
            color="amber-darken-2"
            class="mr-2 hidden-xs"
            variant="tonal"
            rounded="pill"
        />
      </NuxtLink>
      <NuxtLink v-else to="/simmerConnect">
        <v-chip
            border="success thin opacity-50"
            class="me-2 hidden-sm-and-down"
            color="primary"
            link
            text="premium webgl hosting"
            variant="tonal"
            prepend-icon="mdi-star"
        />
      </NuxtLink>
      <UploadMenu :compact="true" />

      <HeaderLogin></HeaderLogin>
    </div>
  </v-app-bar>
</template>

<script setup>
const membershipStore = useMembershipStore();
const opacity = ref(0.5)

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

const handleScroll = () => {
  const scrollPosition = window.scrollY
  const maxScroll = 500
  const minOpacity = 0.5
  const maxOpacity = 1

  opacity.value = Math.min(maxOpacity, minOpacity + (scrollPosition / maxScroll) * (maxOpacity - minOpacity))
}

</script>

<style scoped>
.header-bar {
  backdrop-filter: blur(8px);
}
</style>
