<template>
  <v-footer class="pt-6 pt-md-16 pb-0 d-flex flex-column footer">
    <v-row style="width:100vw">
      <v-col
          cols="12"
          lg="4"
          md="12"
      >
        <v-img
            class="mt-1 mb-6"
            max-width="40"
            src="/images/simmernewyellow.png"
        />

        <p>Web games by Godot and Unity developers.</p>

        <div class="mt-3">
          <v-btn
              v-for="icon in icons"
              :key="icon.icon"
              :icon="icon.icon"
              size="small"
              variant="text"
              :href="icon.link"
              target="_blank"
              rel="noopener noreferrer"
          />
        </div>
      </v-col>

      <v-col
          v-for="(section, i) in sections"
          :key="i"
          cols="6"
          lg="2"
          md="3"
      >
        <p class="text-body-1 font-weight-bold mb-3">{{ section.title }}</p>

        <div
            v-for="item in section.items"
            :key="item.text"
        >
          <v-btn
              class="text-none px-0 justify-start"
              exact
              :href="item.link"
              :ripple="false"
              :text="item.text"
              variant="plain"
          />
        </div>
      </v-col>
    </v-row>

    <p class="py-4 text-caption text-right">
      &copy; 2017-{{ new Date().getFullYear() }} SIMMER industries. All rights reserved.
    </p>
  </v-footer>
</template>
<script setup>
const icons = [
  {
    icon: 'custom:discord',
    link: 'https://discord.gg/simmer'
  },
  {
    icon: 'mdi-youtube',
    link: 'https://www.youtube.com/channel/UC29MxGKgouMyhuVDzzb9MXA?sub_confirmation=1'
  },
]

const sections = [
  {
    title: 'Support',
    items: [
      {
        text: 'FAQ',
        link: '/faq'
      },
      {
        text: 'Godot Guide',
        link: '/articles/share-godot-web-builds-online/'
      },
      {
        text: 'Email',
        link: 'mailto:support@simmer.io'
      },
    ],
  },
  {
    title: 'Company',
    items: [
      {
        text: 'Press Kit',
        link: '/branding'
      },
      {
        text: 'Blog',
        link: '/articles'
      },
    ],
  },
  {
    title: 'Legal',
    items: [
      {
        text: 'Privacy Policy',
        link: '/privacy'
      },
      {
        text: 'Terms of Service',
        link: '/terms'
      },
      {
        text: 'GDPR',
        link: '/gdpr'
      },
    ],
  },
]
</script>
<style scoped>
.footer{
  font-size:14px;
  border-top:1px solid #333;
  width:100vw;
}
</style>
