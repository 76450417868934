<template>
  <div class="app-container">
    <SiteAnnouncement />
    <Header />
    <v-main class="flex-grow-1">
      <slot />
    </v-main>
    <Footer />
    <DebugToolbar></DebugToolbar>
  </div>
</template>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
