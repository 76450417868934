<template>
  <div v-if="!isHidden" class="announcement-message">
    <v-card max-width="400" elevation="4" style="border:1px solid #cd1c55">
      <v-card-text class="d-flex">
        <v-avatar class="mt-1 mr-3" color="primary">
          <v-img alt="Simmer" src="/images/rocco.png"></v-img>
        </v-avatar>
        <div>
          <div class="text-body-1">SIMMER 2.x, Godot Support added!</div>
          <div>
            <p>March 24, 2025: We just launched Godot upload support! </p>
            <p>Instructions? <a href="/">Upload a Godot build</a></p>
            <p>Need help? Got feedback or found a bug? <a href="mailto:support@simmer.io" target="_blank">support@simmer.io</a>, <a href="https://discord.gg/2wGJeH6">discord</a></p>
          </div>
          <div class="text-caption text-grey">-Rocco, simmer.io founder</div>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="mt-1" icon="mdi-close" variant="text" size="small" @click="hideAnnouncement"></v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

// just increment the number every time we change the message to a new announcement.
const ANNOUNCEMENT_ID = 'announcementHidden_4';

const isHidden = ref(true)

onMounted(() => {
  isHidden.value = localStorage.getItem(ANNOUNCEMENT_ID) === 'true'
})

const hideAnnouncement = () => {
  isHidden.value = true
  localStorage.setItem(ANNOUNCEMENT_ID, 'true')
}
</script>

<style scoped>
.announcement-message {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 100000;
}
</style>
