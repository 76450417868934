<template>
  <span>
    <v-btn v-if="loading" loading />
    <!-- fully logged in with dropdown -->

    <v-menu v-else-if="username" location="bottom end">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" variant="tonal" append-icon="mdi-menu-down" class="ml-2">
        <template v-if="$vuetify.display.xs">
          <v-icon>mdi-account</v-icon>
        </template>
        <template v-else>
          @{{ username }}
        </template>
      </v-btn>
    </template>
      <v-list>
        <!--
        :active="false" prevents multiple of these from showing as active when on the profile page
        that looks weird
        -->
        <v-list-item to="/profile" :active="false">
          <template v-slot:prepend>
            <v-icon>mdi-account-circle</v-icon>
          </template>
          <v-list-item-title>Profile Editor</v-list-item-title>
        </v-list-item>
        <v-list-item to="/my_uploads" :active="false">
          <template v-slot:prepend>
            <v-icon>mdi-controller</v-icon>
          </template>
          <v-list-item-title>My Uploads</v-list-item-title>
        </v-list-item>
        <v-list-item to="/my_collections" :active="false">
          <template v-slot:prepend>
            <v-icon>mdi-bookmark-box-multiple</v-icon>
          </template>
          <v-list-item-title>My Collections</v-list-item-title>
        </v-list-item>
        <v-list-item to="/my_loading_screens" :active="false">
          <template v-slot:prepend>
            <v-icon>mdi-reload</v-icon>
          </template>
          <v-list-item-title>My Loading Screens</v-list-item-title>
        </v-list-item>
        <v-list-item to="/subscriptions" :active="false">
          <template v-slot:prepend>
            <v-icon>mdi-credit-card-outline</v-icon>
          </template>
          <v-list-item-title>My Subscriptions</v-list-item-title>
        </v-list-item>
        <v-list-item to="/data" :active="false">
          <template v-slot:prepend>
            <v-icon>mdi-database</v-icon>
          </template>
          <v-list-item-title>My Data</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout" :active="false">
          <template v-slot:prepend>
            <v-icon>mdi-logout</v-icon>
          </template>
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn v-else-if="firebaseUser" :to="loginLink" prepend-icon="mdi-account" variant="tonal">Account</v-btn>
    <v-btn v-else :to="loginLink" variant="tonal" class="ml-2">
      <template v-if="$vuetify.display.xs">
        <v-icon>mdi-login</v-icon>
      </template>
      <template v-else>
        <v-icon start>mdi-login</v-icon>
        Sign In / Sign Up
      </template>
    </v-btn>
  </span>
</template>

<script setup>
import { useMembershipStore } from '~/stores/membership';
import {isPaid} from "~/helpers/helpers.js";

const membershipStore = useMembershipStore();

const loading = ref(true);
const username = ref(null);
const firebaseUser = useCurrentUser();
const auth = useFirebaseAuth();

const reloadUser = async () => {
  // console.log('sudoken reloadUser');
  loading.value = true;
  const currentUserProfile = await useCurrentUserProfile();
  username.value = currentUserProfile?.username;

  membershipStore.setPaidStatus(isPaid(currentUserProfile));

  // todo midpri: total hack. send signal to comments component.
  if (window.comments && window.comments.principal){
    window.comments.logout();
  }

  loading.value = false;
}

auth.onAuthStateChanged(reloadUser);

const logout = async () => {
  await auth.signOut()

  window.localStorage.removeItem('SimmerCommentAs');
  window.localStorage.removeItem('SimmerCommentAsToken');

  const currentMiddleware = useRoute().meta.middleware

  // Only redirect if the current page uses authenticated middleware
  if (currentMiddleware?.includes('authenticated')) {
    await navigateTo('/')
  }
}

const route = useRoute()
const loginLink = computed(() =>
    `/login${!route.path.startsWith('/login') ? '?redirect=' + encodeURIComponent(route.path) : ''}`
);

onMounted(() => {
  loading.value = true;
  // todo lowpri: global trash.
  window.HeaderLoginImplReloadProfile = () => {
    reloadUser();
  }
})

</script>